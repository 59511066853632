import React, {useContext} from "react"
import UserContext from "../context/user"
import Header from '../components/header'
import Section from '../components/section'
import {PeerReviewForm} from "../components/typeform"

const Peer = ({ pageContext: { employee, manager } }) => {
  const user = useContext(UserContext)
  const baseUrl = window.location.protocol+'//'+window.location.host+'/'
  
  if(user.username === employee.id) {
    // navigate('/')
    return (
      <Section>
        <Header/>
        <br/>
        <div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
        Trying to review yourself? Click <a href={baseUrl+"self-review"}>here</a> to navigate to the Self Review URL.
        </div>
        <br/>
      </Section>
    )
  } else {
    return (
      <Section>
        <Header/>
        <br/>
        <div style={{color: 'black', fontSize: '24px', fontWeight: 'bold'}}>
        Peer Review for {employee.name}, {employee.title}
        </div>
        <br/>

        <PeerReviewForm
          peer={employee}
        />
      </Section>
    )
  }
}

export default Peer